<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-if="!$vuetify.breakpoint.smAndDown"
        v-on="on"
        @click="open"
        elevation="0"
        style="
          min-width: 10px !important;
          height: 30px;
          width: 150px;"
        class="mx-2"
        ><v-icon small left>$folder</v-icon>
        {{ $t("new_folder") }}
      </v-btn>
      <v-btn
        v-else
        v-on="on"
        fab
        style="
          min-width: 10px !important;
          padding-right: 2px !important;
          padding-left: 14px !important;
          
        "
        x-small
        class="mx-2"
        elevation="0"
        ><v-icon small left>$folder</v-icon>
      </v-btn>
    </template>
    <v-card id="create-folder">
      <v-card-title>{{ $t("new_folder") }} </v-card-title>
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pt-0 pb-0">
          <v-row justify="center">
            <v-col cols="8" class="pt-4 pb-0">
              <label v-text="$t('tittle')"></label>
              <v-text-field
                outlined
                dense
                v-model="form.name"
                data-vv-validate-on="blur"
                v-validate="'required|min:2'"
                :error-messages="errors.collect('design-form.name')"
                color="secondary"
                data-vv-name="name"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-0 pb-3 pr-5">
          <div class="flex-grow-1"></div>
          <v-btn
            outlined
            @click="dialog = false"
            style="height: 25px; width: 100px"
            elevation="0"
            >{{ $t("cancel") }}</v-btn
          ><v-btn
            type="submit"
            style="
              width: 100px !important;
              color: #363533;
              height: 25px;
              padding-top: 10px !important;
            "
            elevation="0"
          >
            <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
            {{ $t("save", { name: $t("") }) }}
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  data() {
    return { dialog: false, form: {} };
  },
  props: ["route"],
  components: { FileUpload },

  methods: {
    ...mapActions("files", ["addFolder"]),
    validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          let formData = new FormData();
          formData.append("name", this.form.name);
          if (this.route !== null) formData.append("route", this.route);

          this.addFolder(formData).then((data) => {
            this.dialog = false;
            this.$emit("update", data);
            this.form = {};
          });
        }
      });
    },
    open() {
      this.form = {};
    },
  },
};
</script>
<style lang="sass">
.upload
  min-height: 100px
  border-radius: 10px
  border: dashed grey 5px
  text-align: center

  .message
    padding-top: 25px
#create-folder

#create-folder label
  display: block

  height: 25px
</style>
